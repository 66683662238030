<!--
 * @Author: your name
 * @Date: 2021-12-15 11:39:48
 * @LastEditTime: 2021-12-27 11:07:10
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \new-website-1123\src\views\special\car\page1.vue
-->
<template>
  <div class="tk101-page1">
    <div class="title-div">
      <d-title model="ST-TK101" ch="全地形大负载多功能无人车"></d-title>
    </div>
    <div class="img-div">
      <img
        src="https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/resources/special/tk101/1.jpg"
        alt="上太科技"
      />
    </div>
  </div>
</template>

<script>
import dTitle from "@/components/sTitle/title";
export default {
  name: "",
  components: {
    dTitle,
  },
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.tk101-page1 {
  .title-div {
    position: absolute;
    left: 50%;
    top: 13vh;
    -webkit-transform: translate(-50%);
    transform: translate(-50%);
  }
  .img-div {
    width: 100%;
    img {
      width: 100%;
      object-fit: contain;
    }
  }
}
@media screen and (max-width: $mobile-width) {
  .tk101-page1 {
    padding-top: $mobile-header-height;
    background-color: #050505;
    width: 100%;
    overflow: hidden;
    .title-div {
      position: relative;
      left: unset;
      top: unset;
      -webkit-transform: unset;
      transform: unset;
      transform: translateY(40px);
    }
    .img-div {
      width: 100%;
      display: flex;
      justify-content: center;
      img {
        display: block;
        width: 180%;
        object-fit: contain;
      }
    }
  }
}
</style>
