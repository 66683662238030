<!--
 * @Author: your name
 * @Date: 2021-12-15 11:38:15
 * @LastEditTime: 2021-12-27 17:40:16
 * @LastEditors: Please set LastEditors
 * @Description:无人车
 * @FilePath: \new-website-1123\src\views\special\car\index.vue
-->
<template>
  <div class="tk101-page">
    <page1></page1>
    <div class="sticky-box1">
      <div class="sticky-child">
        <page2></page2>
      </div>
    </div>
    <page3
      class="opacity-page scale-page"
      url="https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/resources/special/tk101/3.jpg"
      style="background-size: 80%; background-position: center bottom"
    >
      <span class="label-s">高性能履带机构，无忧前行</span>
      <p class="p-24">
        左右悬挂减震系统，性能稳定可靠，可适应重载避震，可在全地形无忧前行。
      </p>
    </page3>

    <page4 class="opacity-page scale-page"></page4>

    <page3
      class="opacity-page scale-page cc-page5"
      url="https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/resources/special/tk101/5.png"
    >
      <span class="label-s">配置全面升级，防水防尘</span>
      <p class="p-24">
        整体结构、材料以及外置电气元件均采用高防护等级设计，以及防撞耐用的外观结构。超大电池容量可支持超高续航能力，<br />
        也可对外部设备进行供电，为您的作业提供充足保障。
      </p>
    </page3>

    <page3
      class="opacity-page scale-page cc-page6"
      url="https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/resources/special/tk101/7.jpg"
    >
      <span class="label-s">高度模块化设计，植保多元化</span>
      <p class="p-24">
        整车高度模块化设计，可满足不同负载；轻松实现抓取、播散、割草、运输等需求。
      </p>
    </page3>
    <div class="k-div"></div>
  </div>
</template>

<script>
import page1 from "./page1.vue";
import page2 from "./page2.vue";
import page3 from "./page3.vue";
import page4 from "./page4.vue";
import {getNesViewApi} from '@/api/index.js'

export default {
  name: "",
  components: {
    page1,
    page2,
    page3,
    page4,
  },
  data() {
    return {
      scrollTop: 0,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.cScroll, false);
    this.addView()

  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.cScroll, false);
  },
  methods: {
    addView(){
      getNesViewApi({type:1,pid:"bd49c710679e462793e5867324f1f802"})
    },
    /**
     * @description: 监听滚动事件
     */
    cScroll() {
      let html = document.querySelector("html");
      this.scrollTop = html.scrollTop;
      if (window.innerWidth > 800) {
        this.page2s();
      }
    },
    page2s() {
      let box2 = document.querySelector(".sticky-box1");
      let div = box2.querySelector(".tk101-page2");
      let img = box2.querySelector(".img-div");
      let text1 = div.querySelector(".text1-div");
      let text2 = div.querySelector(".text2-div");
      let topLabel = text1.querySelector(".t-top");
      let topDesc = text1.querySelector(".desc-p");
      let msg = text1.querySelector(".msg-ul");
      let filterBox = div.querySelector(".filter-div");

      let ctop = this.scrollTop - box2.offsetTop;
      if (ctop > 2100) {
        let rate = Math.min(1, (ctop - 2100) / 300);
        text2.style.opacity = rate;
        text1.style.opacity = 0;
        filterBox.style.opacity = 1;
      } else if (ctop > 1800) {
        let rate = Math.min(1, (ctop - 1800) / 300);
        text2.style.opacity = 0;
        text1.style.opacity = 1 - rate;
        filterBox.style.opacity = rate;
      } else if (ctop > 1600) {
        text1.style.opacity = 1;
        topLabel.style.transform = `translateY(0%)`;
        msg.style.opacity = topDesc.style.opacity = 1;
        msg.style.transform = `translateY(0%)`;
        text1.style.opacity = 1;
      } else if (ctop > 1200) {
        let rate = Math.min(1, (ctop - 1200) / 400);
        topLabel.style.transform = `translateY(${(1 - rate) * 100}%)`;
        msg.style.opacity = topDesc.style.opacity = rate;
        msg.style.transform = `translateY(-${(1 - rate) * 20}%)`;
        text1.style.opacity = 1;
      } else if (ctop > 700) {
        msg.style.opacity = topDesc.style.opacity = 0;
        topLabel.style.transform = `translateY(100%)`;
        let rate = (ctop - 700) / 500;
        let scale = Math.max(1, (1 - rate) * 4);
        text1.style.transform = `scale(${scale})`;
        topLabel.style.opacity = rate;
      } else if (ctop > 200) {
        topLabel.style.opacity = 0;
        let rate = (ctop - 200) / 500;
        img.style.width = 60 + rate * 10 + "%";
        img.style.transform = `translateY(${rate * 50}px)`;
      } else if (ctop < 200) {
        topLabel.style.opacity = 0;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.sticky-child {
  position: sticky;
  top: 0;
}
.sticky-box1 {
  height: calc(100vh + 2700px);
}
.cc-page5 {
  background-size: 50%;
  background-position: center bottom 50px;
}
.cc-page6 {
  background-size: 80%;
  background-position: center bottom;
}
.k-div {
  height: 80px;
}
@media screen and (max-width: $mobile-width) {
  .sticky-child {
    position: relative;
  }
  .sticky-box1 {
    height: auto;
  }

  .cc-page5 {
    background-size: 86% !important;
    background-position: center bottom !important;
    height: 8.5rem !important;
  }

  .cc-page6 {
    width: 86%;
    margin: auto;
    background-size: 100%;
    background-position: 46% bottom !important;
    height: 9.5rem !important;
  }

  .k-div {
    height: 0.36rem;
  }
}
</style>
