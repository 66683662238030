<!--
 * @Author: your name
 * @Date: 2021-12-15 11:42:49
 * @LastEditTime: 2021-12-24 11:47:33
 * @LastEditors: Please set LastEditors
 * @Description:
 * @FilePath: \new-website-1123\src\views\special\tk101\page2.vue
-->
<template>
  <div class="tk101-page2 pt80">
    <div class="img-div">
      <img
        src="https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/resources/special/tk101/2.png"
        alt="上太科技"
      />
    </div>

    <div class="filter-div"></div>

    <div class="text1-div pt80">
      <div class="t-top">
        <span class="label-s">
          <span>强劲</span>
          <span class="f-span">、</span>
          <span>强大</span>
          <span class="f-span">、</span>
          <span>强动力</span>
        </span>
        <p class="desc-p p-24"></p>
      </div>
      <ul class="msg-ul"></ul>
    </div>
    <div class="text2-div pt80">
      <span class="label2-s">全地形大负载</span>
      <p class="desc-p p-24">
        采用履带式差速机械机构，具有极强的地形适应能力，专为特殊作业环境设计，具有差速自转、载重能力强、机械损耗小、防护等级高等特点。
      </p>
      <ul class="n-ul">
        <li>
          <span class="c-s din">双电机驱动，全地形越野</span>
        </li>
        <li>
          <span class="c-s din">高性能履带机构，无忧前行</span>
        </li>
        <li>
          <span class="c-s din">高度模块化设计，植保多元化</span>
        </li>
        <li>
          <span class="c-s din">IP52级防水防尘</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.tk101-page2 {
  position: relative;
  height: 100vh;
  color: #fff;
  text-align: center;
  overflow: hidden;
  .filter-div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($color: #000, $alpha: 0.7);
    opacity: 0;
    z-index: 1;
  }
  .img-div {
    height: 100%;
    width: 40%;
    // background-image: url("https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/resources/special/tk101/4.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center bottom;
    margin: auto;
    position: relative;
    display: flex;
    justify-content: center;
    img {
      width: 100%;
      // height: 100%;
      object-fit: contain;
      position: absolute;
      bottom: 0;
    }
  }
  .text1-div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    z-index: 2;
    .t-top {
      margin-top: 200px;
      transform: translateY(100%);
      opacity: 0;
      .desc-p {
        opacity: 0;
      }
    }
    .label-s {
      font-size: 90px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: $color-active;
    }
    .msg-ul {
      margin-top: 100px;
      opacity: 0;
      transform: translateY(-20%);
      li {
        font-size: 60px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #ffffff;
        margin-top: 10px;
      }
    }
  }
  .text2-div {
    position: absolute;
    top: 10vh;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 2;
    .label2-s {
      font-size: 60px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #ffffff;
    }
    .n-ul {
      width: 78%;
      margin: auto;
      margin-top: 110px;
      text-align: center;
      li {
        font-size: 24px;
        font-family: PingFang SC;
        font-weight: 500;
        color: rgba($color: #fff, $alpha: 0.8);
        margin-bottom: 50px;
        .c-s {
          font-size: 56px;
          font-weight: bold;
          color: $color-active;
        }
      }
    }
  }
  .desc-p {
    width: 1000px;
    margin: auto;
    margin-top: 12px;
  }
}
@media screen and (max-height: 800px) {
  .tk101-page2 {
    .text1-div {
      .t-top {
        margin-top: 60px;
        transform: translateY(100%);
        opacity: 0;
        .desc-p {
          opacity: 0;
        }
      }
      .label-s {
        font-size: 70px;
      }
      .msg-ul {
        margin-top: 80px;
        opacity: 0;
        transform: translateY(-20%);
        li {
          font-size: 48px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #ffffff;
          margin-top: 10px;
        }
      }
    }
  }
}

@media screen and (max-width: $mobile-width) {
  .tk101-page2 {
    position: relative;
    height: auto;
    width: 100%;
    overflow: hidden;

    .filter-div {
      opacity: 1;
    }
    .img-div {
      height: auto;
      width: 100%;
      display: flex;
      justify-content: center;
      img {
        width: 150%;
        // height: 100%;
        position: relative;
        top: 0;
      }
    }
    .desc-p {
      width: 86%;
    }

    .text1-div {
      position: absolute;
      .t-top {
        margin-top: 1.36rem;
        transform: translateY(0);
        opacity: 1;
        .desc-p {
          opacity: 1;
        }
      }
      .label-s {
        font-size: 0.82rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        .f-span {
          display: none;
        }
      }
      .msg-ul {
        margin-top: 0.73rem;
        opacity: 1;
        transform: translateY(0);
        li {
          font-size: 0.55rem;
          margin-top: 10px;
        }
      }
    }
    .text2-div {
      position: relative;
      top: 0;
      opacity: 1;
      padding-bottom: 0.64rem;
      .label2-s {
        font-size: 0.55rem;
      }
      .n-ul {
        width: 86%;
        margin-top: 0.91rem;
        li {
          font-size: 0.44rem;
          margin-bottom: 0.45rem;
          .c-s {
            font-size: 0.44rem;
          }
        }
      }
    }
  }
}
</style>
