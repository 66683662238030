<!--
 * @Author: your name
 * @Date: 2021-12-15 13:22:39
 * @LastEditTime: 2022-01-04 16:53:38
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \new-website-1123\src\views\special\tk101\page4.vue
-->
<template>
  <div class="tk101-page4 pt80">
    <div class="title-div">
      <span class="label-s">
        <span>双电机驱动</span>
        <span class="f-span">，</span>
        <span>全地形越野</span>
      </span>
      <p class="p-24">
        机动性强，重心位置低，1300W双电机驱动，具有超强的越障和爬坡能力;轻松应对平原、林地、丘陵等多地形。
      </p>
    </div>
    <ul class="msg-ul">
      <li>
        <span>1300W</span>
        <span class="small-span">双电机驱动</span>
      </li>
      <li class="small">双电机驱动</li>
      <li>
        <span>高效作业</span>
        <span class="small-span">满足果园、麦田地等多场景</span>
      </li>
      <li class="small">满足果园、麦田地等多场景</li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.tk101-page4 {
  height: 100vh;
  text-align: center;
  position: relative;
  background-image: url("https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/resources/special/tk101/4.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  .label-s {
    font-size: 60px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #ffffff;
    display: block;
    margin-bottom: 10px;
  }

  .msg-ul {
    text-align: left;
    position: absolute;
    top: 480px;
    left: 15vw;
    font-size: 70px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: $color-active;

    .small {
      font-size: 22px;
      margin-top: 32px;
      font-weight: normal;
      color: $color-text;
      margin-bottom: 80px;
    }
  }
}
@media screen and (max-height: 798px) {
  .tk101-page4 {
    .msg-ul {
      font-size: 60px;
      line-height: 80px;
    }
  }
}

@media screen and (max-width: $mobile-width) {
  .tk101-page4 {
    height: 12rem;
    padding-top: $mobile-header-height;
    background-position: 80% bottom;
    background-size: 240% auto;
    .title-div {
      width: 86%;
      margin: auto;
    }
    .label-s {
      font-size: 0.55rem;
      margin-top: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      .f-span {
        display: none;
      }
    }

    .msg-ul {
      text-align: left;
      position: relative;
      top: 0.91rem;
      left: 0;
      font-size: 0.55rem;
      line-height: 0.55rem;
      width: 86%;
      margin: auto;
      display: flex;
      li {
        display: flex;
        flex-direction: column;
        flex: 1;
      }
      .small-span {
        font-size: 0.22rem;
        margin-top: 0;
        font-weight: normal;
        color: rgba($color: #fff, $alpha: 0.6);
        margin-bottom: 0;
      }
      .small {
        display: none;
      }
    }
  }
}
</style>
