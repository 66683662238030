<!--
 * @Author: your name
 * @Date: 2021-12-15 13:20:49
 * @LastEditTime: 2021-12-24 13:51:54
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \new-website-1123\src\views\special\tk101\page3.vue
-->
<template>
  <div
    class="tk101-page3 pt80"
    :style="{
      'background-image': `url(${url})`,
    }"
  >
    <div class="text-div">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  props: {
    url: {
      type: String,
      required: true,
    },
  },
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.tk101-page3 {
  height: 100vh;
  padding-top: 100px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-repeat: no-repeat;
  .img-div {
    display: none;
  }
  .text-div {
    text-align: center;
    ::v-deep {
      .label-s {
        font-size: 60px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #ffffff;
        display: block;
        margin-bottom: 10px;
      }
    }
  }
}
@media screen and (max-width: $mobile-width) {
  .tk101-page3 {
    padding-top: 0.45rem;
    height: 11.09rem;
    background-size: 230% !important;
    background-position: 74% bottom !important;
    background-color: #171717;
    .text-div {
      width: 86%;
      margin: auto;
      ::v-deep {
        .label-s {
          font-size: 0.55rem;
          margin-bottom: 10px;
        }
      }
    }
  }
}
</style>
